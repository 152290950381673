/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-northeast-1",
    "aws_cognito_identity_pool_id": "ap-northeast-1:1735889e-1016-4fe3-a130-13687dbd7f3f",
    "aws_cognito_region": "ap-northeast-1",
    "aws_user_pools_id": "ap-northeast-1_S64a5QQlz",
    "aws_user_pools_web_client_id": "9vureqnf8gh44o6cr7b30593s",
    "oauth": {},
    "aws_appsync_graphqlEndpoint": "https://qillqoshevahdmsfr2puj4pnfy.appsync-api.ap-northeast-1.amazonaws.com/graphql",
    "aws_appsync_region": "ap-northeast-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cloud_logic_custom": [
        {
            "name": "hitoterracerestapi",
            "endpoint": "https://ooeiz1gk74.execute-api.ap-northeast-1.amazonaws.com/prd",
            "region": "ap-northeast-1"
        }
    ],
    "aws_content_delivery_bucket": "hitoterrace-web-prd",
    "aws_content_delivery_bucket_region": "ap-northeast-1",
    "aws_content_delivery_url": "https://d1n8s0xv7fuf9x.cloudfront.net",
    "aws_mobile_analytics_app_id": "d3b17a86a13e4d5ab72108dbcf291ccf",
    "aws_mobile_analytics_app_region": "us-west-2",
    "aws_user_files_s3_bucket": "hitoterrace161539-prd",
    "aws_user_files_s3_bucket_region": "ap-northeast-1"
};


export default awsmobile;
